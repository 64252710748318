import { isLeft } from "fp-ts/lib/Either";
import { inject } from "mobx-react";
import { useEffect } from "react";
import type { Store } from "@inferno/renderer-shared-core";
import { stripNamespace } from "@inferno/renderer-shared-core";
import { TwitterBlockValueResolver } from "@ihr-radioedit/inferno-core";
import type { BlockFragment } from "@ihr-radioedit/inferno-webapi";
import { Container } from "../../ui";

export const Twitter = inject("store")(({ block, store }: { block: BlockFragment; store?: Store }) => {
  if (!store || store.disableThirdParty) {
    return null;
  }

  const value = TwitterBlockValueResolver.decode(block.value);
  if (isLeft(value)) {
    return null;
  }

  useEffect(() => {
    store.storeBlock(block);
  });

  const { use_site_handle, handle } = value.right;

  const username = use_site_handle ? store.site.sections.social?.twitter_name : handle;

  if (!username) {
    return null;
  }

  return (
    <Container className="component-twitter">
      <a
        className="twitter-timeline"
        data-theme={stripNamespace(store.site.sections.design?.color_scheme?.id) || "light"}
        style={{ width: "100%", height: "550px" }}
        data-dnt="true"
        href={`https://twitter.com/${username}`}
        data-widget-id="356943653601677313"
        data-link-color="#4aa5ff"
      >
        Tweets by @{username}
      </a>
      <div className="component-embed-script" data-src="//platform.twitter.com/widgets.js" />
    </Container>
  );
});

export default Twitter;
